import merge from 'lodash.merge';
import moment from 'moment';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  dmpCommandSuccessContextualizedType,
  dmpRemoteCommandSuccessContextualizedType,
} from 'dmpconnectjsapp-base/actions';
import { documentStatuses } from 'dmpconnectjsapp-base/helpers/findDocuments';
import { toast } from 'react-toastify';
import { dmpconnectUserActionConstants } from '../constants';
import env from '../../envVariables';

const initialState = {};

// const resetNewFiles = (state, ins) => {
//   const {
//     findDocumentsCache: {
//       [ins]: {
//         documents: cachedDocs = {},
//         ...timestamps
//       } = {},
//       ...allCache
//     },
//     ...allState
//   } = state;
//
//   const resetCachedDocs = Object.fromEntries(
//     Object.values(cachedDocs).map((item) => {
//       const { i_handle, isNew, ...data } = item;
//       return [item.s_uniqueId, { ...data, isNew: false }];
//     }),
//   );
//
//   return {
//     ...allState,
//     findDocumentsCache: {
//       ...allCache,
//       [ins]: {
//         documents: resetCachedDocs,
//       },
//       ...timestamps,
//     },
//   };
// };

const unsetNewDocumentStatus = (state, action) => {
  const { uniqueId, ins } = action;
  const {
    [ins]: {
      documents: {
        [uniqueId]: {
          isNew,
          ...documentInfo
        } = {},
        ...allDocs
      } = {},
      ...timestamps
    } = {},
    ...allCache
  } = state;

  return {
    ...allCache,
    [ins]: {
      documents: {
        [uniqueId]: {
          isNew: false,
          ...documentInfo,
        },
        ...allDocs,
      },
      ...timestamps,
    },
  };
};

const markAllAsRead = (state, ins) => {
  const {
    [ins]: {
      documents,
      ...timestamps
    } = {},
    ...allCache
  } = state;

  return {
    ...allCache,
    [ins]: {
      ...timestamps,
      documents: Object.entries(documents).reduce((result, [key, doc]) => ({
        ...result,
        [key]: { ...doc, isNew: false },
      })),
    },
  };
};
const mergeFindDocumentsResults = (state, action) => {
  const { data: { Documents }, context: { subSection, enableCache = true, docTypesTranslation = [] } } = action;
  const {
    [subSection]: {
      documents: docs = {},
      timestamp: lastSearchTimestamp = null,
      ...rest
    } = {},
    ...allCache
  } = state;

  let cachedDocs = docs;

  const lastSearchTimestampUtc = moment(lastSearchTimestamp).utc().format('YYYYMMDDHHmmss');

  // index files by their uniqueId
  const documentsByUniqueId = Documents.reduce((obj, item) => {
    const {
      i_handle, s_submissionDate, s_typeCode, ...data
    } = item;
    const { s_creationDate } = data;
    const docId = `${item.s_uniqueId}__${item.s_uuid}`;
    const doc = cachedDocs[docId];
    const date = s_submissionDate || s_creationDate;
    const isNew = enableCache && ((doc && doc.isNew) || (lastSearchTimestamp !== null && date > lastSearchTimestampUtc));
    const code = docTypesTranslation.find(t => t.s_oldCode === s_typeCode);
    const typeCode = code ? code.s_newCode : s_typeCode;
    return {
      [docId]:
        {
          ...data,
          isNew,
          s_submissionDate: moment.utc(date, 'YYYYMMDDHHmmss').local().format('YYYYMMDDHHmmss'),
          s_typeCode: typeCode,
        },
      ...obj,
    };
  }, {});

  if (enableCache) merge(cachedDocs, documentsByUniqueId);
  else cachedDocs = documentsByUniqueId;
  const timestamp = Date.now();

  return {
    ...allCache,
    [subSection]: {
      version: parseInt(env.REACT_APP_DOCUMENT_CACHE_VERSION, 10),
      documents: cachedDocs,
      timestamp: enableCache ? timestamp : null,
      previousTimestamp: enableCache ? lastSearchTimestamp : null,
      ...rest,
    },
  };
};


const mergeSubmissionSetDocumentsResult = (state, action) => {
  const { context: { ins } } = action;
  return mergeFindDocumentsResults(state, {
    ...action,
    context: { ...action.context, subSection: ins },
  });
};

const removeDocumentFromCache = (state, action) => {
  const { uniqueUUid, ins } = action;
  const {
    [ins]: {
      documents: {
        [uniqueUUid]: document,
        ...documents
      } = {},
      ...rest
    } = {},
    ...allCache
  } = state;

  return {
    ...allCache,
    [ins]: {
      documents,
      ...rest,
    },
  };
};

const removeDocumentFromCacheAfterDelete = (state, action) => {
  const { command: { s_ins: ins, s_uniqueId: uniqueId } } = action;
  const {
    [ins]: {
      documents: cachedDocs = {},
      ...rest
    } = {},
    ...allCache
  } = state;

  const cachedDocsAfterRemoved = { ...cachedDocs };
  Object.entries(cachedDocs)
    .filter(([id, doc]) => doc.s_uniqueId === uniqueId)
    .forEach(([id, doc]) => delete cachedDocsAfterRemoved[id]);

  return {
    ...allCache,
    [ins]: {
      documents: cachedDocsAfterRemoved,
      ...rest,
    },
  };
};

const updateDocumentStatusInCache = (state, action) => {
  const { context: { subSection: uniqueUUid, ins } } = action;
  const {
    [ins]: {
      documents: {
        [uniqueUUid]: document,
        ...documents
      } = {},
      ...rest
    } = {},
    ...allCache
  } = state;

  if (!document) return state;

  const { i_document_Status, ...data } = document;
  const newStatus = i_document_Status === documentStatuses.APPROVED ? documentStatuses.ARCHIVED : documentStatuses.APPROVED;

  const newDoc = { i_document_Status: newStatus, ...data };

  return {
    ...allCache,
    [ins]: {
      documents: {
        ...documents,
        [uniqueUUid]: newDoc,
      },
      ...rest,
    },
  };
};

const updateDocumentVisibilityInCache = (state, action) => {
  const {
    context: {
      subSection: uniqueUUid,
      ins,
    },
    command: {
      i_newVisibility,
    },
  } = action;

  const {
    [ins]: {
      documents: {
        [uniqueUUid]: document,
        ...documents
      } = {},
      ...rest
    } = {},
    ...allCache
  } = state;

  if (!document) return state;

  const { i_document_Visibility, ...data } = document;

  const newDoc = { i_document_Visibility: i_newVisibility, ...data };

  return {
    ...allCache,
    [ins]: {
      documents: {
        ...documents,
        [uniqueUUid]: newDoc,
      },
      ...rest,
    },
  };
};


const updateDocumentExportStatusInCache = (state, action) => {
  const { context: { subSection: uniqueUUid, ins }, data: { s_status, s_id } } = action;
  toast.update(uniqueUUid, {
    render: 'Le document a été exporté avec succès',
    type: 'success',
    autoClose: 2000,
  });
  const {
    [ins]: {
      documents: {
        [uniqueUUid]: document,
        ...documents
      } = {},
      ...rest
    } = {},
    ...allCache
  } = state;

  if (!document) return state;

  const { export: exportStatus, ...data } = document;

  const newDoc = { ...data, export: { status: s_status, id: s_id, timestamp: moment().unix() } };

  return {
    ...allCache,
    [ins]: {
      documents: {
        ...documents,
        [uniqueUUid]: newDoc,
      },
      ...rest,
    },
  };
};

const updateDocumentSubmissionSetId = (state, action) => {
  const { context: { subSection: uniqueUUid, ins }, data: { s_submissionSetId } } = action;
  toast.update(uniqueUUid, {
    render: 'Le document a été exporté avec succès',
    type: 'success',
    autoClose: 2000,
  });
  const {
    [ins]: {
      documents: {
        [uniqueUUid]: document,
        ...documents
      } = {},
      ...rest
    } = {},
    ...allCache
  } = state;

  if (!document) return state;

  return {
    ...allCache,
    [ins]: {
      documents: {
        ...documents,
        [uniqueUUid]: { ...document, s_submissionSetId },
      },
      ...rest,
    },
  };
};

const emptyCache = (state, ins = null) => {
  if (ins !== null) {
    const { [ins]: cache, ...allCache } = state;
    if (cache && Object.keys(cache).length > 0) {
      toast.info('Réinitialisation du cache des documents');
    }
    return { ...allCache };
  }

  toast.info('Réinitialisation du cache des documents');
  return initialState;
};

const setHr = (state, { ins, documentId, parsedHr }) => {
  const {
    [ins]: {
      hr: cachedHr,
      ...rest
    } = {},
    ...allCache
  } = state;

  return {
    [ins]: {
      hr: {
        documentId,
        parsedHr,
      },
      ...rest,
    },
    ...allCache,
  };
};

const updateDocumentsNextVersion = (state, action) => {
  const { updates, ins } = action;

  const {
    [ins]: { documents, ...rest } = {},
    ...allCache
  } = state;

  const newDocuments = Object.entries(documents).reduce((docs, [uniqueUUId, doc]) => {
    const update = updates.find(up => up.uniqueUUid === uniqueUUId);

    if (update) {
      return {
        ...docs,
        [uniqueUUId]: {
          ...doc,
          i_document_Status: documentStatuses.DEPRECATED,
          s_nextUUId: update.nextUUId,
        },
      };
    }
    return { ...docs, [uniqueUUId]: doc };
  }, {});

  return {
    ...allCache,
    [ins]: {
      ...rest,
      documents: newDocuments,
    },
  };
};

const updateDocumentNextVersion = (state, action) => {
  const {
    uniqueUUid, nextVersionUuid, status, ins,
  } = action;
  const {
    [ins]: {
      documents: {
        [uniqueUUid]: document,
        ...documents
      } = {},
      ...rest
    } = {},
    ...allCache
  } = state;

  if (!document) return state;

  const { i_document_Status, s_nextUUId, ...data } = document;

  const newDoc = { i_document_Status: status, s_nextUUId: nextVersionUuid, ...data };

  return {
    ...allCache,
    [ins]: {
      documents: {
        ...documents,
        [uniqueUUid]: newDoc,
      },
      ...rest,
    },
  };
};

export function dmpconnectDocumentsCache(state = initialState, action) {
  switch (action.type) {
    case dmpCommandSuccessContextualizedType(apiSections.FIND_DOCUMENTS_SECTION):
      return mergeFindDocumentsResults(state, action);
    case dmpconnectUserActionConstants.DMPC_REMOVE_DOCUMENT_FROM_CACHE:
      return removeDocumentFromCache(state, action);
    case dmpCommandSuccessContextualizedType(apiSections.DELETE_DOCUMENT_SECTION):
    case dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DELETE_DOCUMENT):
      return removeDocumentFromCacheAfterDelete(state, action);
    case dmpCommandSuccessContextualizedType(apiSections.UPDATE_DOCUMENT_STATUS_SECTION):
      return updateDocumentStatusInCache(state, action);
    case dmpCommandSuccessContextualizedType(apiSections.UPDATE_DOCUMENT_VISIBILITY_SECTION):
      return updateDocumentVisibilityInCache(state, action);
    case dmpconnectUserActionConstants.DMPC_EMPTY_DOCUMENTS_CACHE:
      return emptyCache(state, action.ins);
    case dmpconnectUserActionConstants.DMPC_UNSET_NEW_DOCUMENT_STATUS:
      return unsetNewDocumentStatus(state, action);
    case dmpconnectUserActionConstants.DMPC_MARK_ALL_DOCS_AS_READ:
      return markAllAsRead(state, action.ins);
    case dmpconnectUserActionConstants.DMPC_SET_HR:
      return setHr(state, action);
    case dmpCommandSuccessContextualizedType(apiSections.EXPORT_CDA_DOCUMENT):
      return updateDocumentExportStatusInCache(state, action);
    case dmpCommandSuccessContextualizedType(apiSections.GET_SUBMISSION_SET_ID):
      return updateDocumentSubmissionSetId(state, action);
    case dmpCommandSuccessContextualizedType(apiSections.GET_SUBMISSION_SET_DOCUMENTS):
      return mergeSubmissionSetDocumentsResult(state, action);
    case dmpconnectUserActionConstants.DMPC_UPDATE_DOCUMENT_NEXT_VERSION:
      return updateDocumentNextVersion(state, action);
    case dmpconnectUserActionConstants.DMPC_UPDATE_DOCUMENTS_NEXT_VERSION:
      return updateDocumentsNextVersion(state, action);
    default:
      return state;
  }
}
