import moment from 'moment';
import { generateId } from 'dmpconnectjsapp-base/utils/dataUtils';
import { push } from 'connected-react-router';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import React from 'react';
import { documentStatuses, getCdaHeaders, getDocumentData } from 'dmpconnectjsapp-base/helpers/findDocuments';
import FileType from 'file-type/core';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { documentFormatB64Signatures, documentFormatPreviews, documentFormats } from '../constants';
import { hrSectionsLabels, typeDocToHrSection } from '../constants/hrConstants';
import {
  addMssEmailAttachment,
  resetMssEmailContent,
  setModalConfirmation,
  setModalInformation,
  setShowMssPopup,
} from '../actions';
import { canConsultDocument, getUniqueUUid } from '../rules/documentRules';
import { base64ToArrayBuffer } from '../utils/dataUtils';
import { getCdaContent } from './findDocuments';

export const sortDocuments = documents => documents.sort((a, b) => {
  if (a.s_creationDate > b.s_creationDate) {
    return -1;
  }
  if (a.s_creationDate < b.s_creationDate) {
    return 1;
  }
  return 0;
});

export const isPatientDoc = (document) => {
  const { s_typeCode, s_classCode } = document;
  return s_classCode === '90' || s_typeCode.indexOf('DOCPAT') > -1 || s_typeCode.indexOf('EXPPAT') > -1;
};

export const getLastVSM = (documents) => {
  let vsm = null;
  const sortedDocuments = sortDocuments(documents);
  if (sortedDocuments && sortedDocuments.length > 0) {
    vsm = sortedDocuments.find(doc => doc.i_document_Format === documentFormats.vsm);
  }
  return vsm || undefined;
};

export const getLastHR = (documents) => {
  let hr = null;
  const sortedDocuments = sortDocuments(documents);
  if (sortedDocuments && sortedDocuments.length > 0) {
    hr = sortedDocuments.find(doc => doc.s_typeCode === 'REMB');
  }
  return hr || undefined;
};
export const getParsedHrFromDocumentsCache = (state, ins) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        hr: {
          parsedHr,
        } = {},
      } = {},
    },
  } = state;
  return parsedHr;
};

export const getCachedHr = (state, ins) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        hr = {},
      } = {},
    },
  } = state;
  return hr;
};


export const detectFormat = async (documentFormat, content) => {
  if (documentFormat > 0 && documentFormat !== documentFormats.textPlain && documentFormatPreviews[documentFormat]) return documentFormat;
  if (!content) return null;
  // const format = null;

  if (content.length > 3000000) return documentFormat;

  // Vérification du format si c'est un document texte : certains documents ne sont pas du texte et peuvent générer des erreurs.
  if (documentFormat === documentFormats.textPlain) {
    const decodedContent = base64ToArrayBuffer(content);
    const fileType = await FileType.fromBuffer(decodedContent);
    // FileType.fromBuffer ne détecte pas les format text, si on a un retour alors qu'on attend du texte, on déclenche l'erreur de format
    if (fileType && !fileType.mime === 'application/xml') {
      return -1;
    }
  }

  let format = documentFormat;
  Object.entries(documentFormatB64Signatures).some(([sign, fileFormat]) => {
    if (content.indexOf(sign) === 0) {
      format = fileFormat;
      return true;
    }
    return false;
  });
  return format;
};

export const timelineEventType = {
  EVENT: 'EVENT',
  MARKER: 'MARKER',
};

export const addToList = (list, item) => {
  const newList = { ...list };
  const date = moment(item.date || item.from, ['YYYYMMDDHHmmss', 'YYYYMMDD']);
  const monthYear = date.format('MMMM YYYY');
  const day = date.format('YYYYMMDD');
  if (!Object.keys(newList).includes(monthYear)) {
    Object.assign(newList, { [monthYear]: {} });
  }

  if (!Object.keys(newList[monthYear]).includes(day)) {
    Object.assign(newList[monthYear], { [day]: [] });
  }

  newList[monthYear][day].push(item);

  return newList;
};

export const mergeDocsAndHr = (documents, hr, categories, sortDate) => {
  let list = {};
  documents.forEach((doc) => {
    const {
      s_typeCode: docSection,
      s_submissionDate: submissionDate,
      s_creationDate: creationDate,
      s_serviceStopDate: serviceStopDate,
      s_serviceStartDate: serviceStartDate,
      s_uniqueId: docUniqueId,
      s_uuid: docUuid,
      s_title: docTitle,
      Authors: authors,
    } = doc;
    let docDate;

    if (sortDate === 'submissionDate') {
      docDate = submissionDate;
    } else if (sortDate === 'serviceStopDate') {
      docDate = serviceStopDate || serviceStartDate;
    } else {
      docDate = creationDate;
    }

    if (docUniqueId) {
      const category = categories.find(cat => cat.code === docSection);
      list = addToList(list, {
        type: timelineEventType.EVENT,
        id: getUniqueUUid(docUniqueId, docUuid),
        source: 'DMP',
        section: typeDocToHrSection[docSection],
        date: docDate,
        document: {
          ...doc,
          uniqueId: docUniqueId,
          title: docTitle,
        },
        title: category ? category.label : 'Inconnu',
        subTitle: `${authors[0].s_hpName} ${authors[0].s_hpGiven}`,

        author: authors.map(author => ({
          profession: isPatientDoc(doc) ? 'pat' : author.s_hpProfession,
          professionOid: author.s_hpProfessionOid,
          professionDesc: isPatientDoc(doc) ? 'Patient' : author.s_hpProfessionDescription,
          given: author.s_hpGiven,
          name: author.s_hpName,
          specialty: author.s_hpSpeciality,
          specialtyDesc: author.s_hpSpecialityDescription,
          rpps: author.s_hpInternalId,
          es: { name: author.s_hpInstitution },
        })),
      });
    }
  });

  Object.entries(hr || {}).forEach(([key, section]) => {
    const { values = [] } = section;
    values.forEach(((value) => {
      const {
        date,
        from,
        to,
        label,
        performer: performer = {},
        author: author = {},
      } = value;

      list = addToList(list, {
        type: timelineEventType.EVENT,
        id: generateId(20),
        source: 'Historique des Remboursements',
        section: key,
        date: date || from,
        dateFrom: from,
        dateTo: to,
        title: hrSectionsLabels[key],
        subTitle: label,

        author: [{
          profession: author.professionCode,
          professionDesc: author.professionLabel,
          professionOid: author.formation ? '1.2.250.1.71.1.2.8' : '1.2.250.1.71.1.2.7',
          given: (author.person || {}).given,
          name: (author.person || {}).name,
          specialty: author.specialty,
          rpps: author.rpps,
          es: author.es,
        }],

        performer: [{
          profession: performer.professionCode,
          professionDesc: performer.professionLabel,
          professionOid: performer.formation ? '1.2.250.1.71.1.2.8' : '1.2.250.1.71.1.2.7',
          given: (performer.person || {}).given,
          name: (performer.person || {}).name,
          specialty: performer.specialty,
          rpps: performer.rpps,
          es: performer.es,
        }],
      });
    }));
  });

  return list;
};

export const useMergedDocsAndHR = (documents, hr, categories, accessRights, sortDate) => {
  const [data, setData] = React.useState({});
  React.useEffect(() => {
    const filteredDocs = Object.values(documents).filter(doc => (
      // not HR
      getUniqueUUid(doc.s_uniqueId, doc.s_uuid) !== hr.documentId
      // can be consulted
      && canConsultDocument(doc, accessRights)
      // approved
      && doc.i_document_Status === documentStatuses.APPROVED
    ));
    const mergedDocsHr = mergeDocsAndHr(filteredDocs, hr.parsedHr || {}, categories, sortDate);

    setData(mergedDocsHr);
  }, [documents, hr, categories, sortDate]);

  return data;
};

export const existsEventAfterDate = (date, mergedDocsAndHr) => (
  Object.values(mergedDocsAndHr).some(month => (
    Object.values(month).some(day => (
      day.some((event) => {
        const eventDate = moment(event.date, ['YYYYMMDDHHmmss', 'YYYYMMDD']);
        const checkDate = moment(date, ['YYYYMMDDHHmmss', 'YYYYMMDD']);
        return eventDate.diff(checkDate, 'day') >= 0;
      })
    ))
  ))
);

export const closeConfirmModal = (dispatch) => {
  dispatch(setModalConfirmation({
    show: false,
    title: '',
    message: '',
    handleConfirm: null,
  }));
};

export const confirmConsultDocument = (dispatch, ins, document) => {
  const { s_title: toConsultDocumentTitle = '' } = document;
  dispatch(setModalConfirmation({
    show: true,
    title: 'Consultation de document',
    message: `Le patient vous autorise-t-il à consulter le document ${toConsultDocumentTitle} ?`,
    handleConfirm: () => {
      closeConfirmModal(dispatch);
      dispatch(push(`/dmp/${encodeIns(ins)}/document/${encodeIns(getUniqueUUid(document.s_uniqueId, document.s_uuid))}`));
    },
    confirmButtonText: 'Oui',
    confirmButtonVariant: 'primary',
    cancelButtonText: 'Non',
  }));
};

export const confirmArchiveDocument = (dispatch, ins, document, onConfirm) => {
  const { s_title: toArchiveDocumentTitle = '', i_document_Status } = document;

  const title = i_document_Status === documentStatuses.APPROVED ? 'Archiver le document' : 'Désarchiver le document';
  const action = i_document_Status === documentStatuses.APPROVED ? 'l\'archivage' : 'le désarchivage';
  const actionVerb = i_document_Status === documentStatuses.APPROVED ? 'archiver' : 'désarchiver';

  dispatch(setModalConfirmation({
    show: true,
    title,
    message: `Confirmez vous ${action} du document ${toArchiveDocumentTitle} ?`,
    handleConfirm: onConfirm,
    confirmButtonText: `Oui, ${actionVerb}`,
    confirmButtonVariant: 'danger',
    cancelButtonText: 'Annuler',
  }));
};

export const confirmDeleteDocument = (dispatch, ins, document, onConfirm, redirectAfterDelete) => {
  const { s_title: toDeleteDocumentTitle = '' } = document;
  dispatch(setModalConfirmation({
    show: true,
    title: 'Supprimer le document',
    message: (
      <>
        Confirmez vous la suppression du document
        {' '}
        {toDeleteDocumentTitle}
        {' '}
        ?
        {redirectAfterDelete && (
          <>
            <br />
            Vous serez redirigé vers la liste des documents.
          </>
        )}
      </>
    ),
    confirmButtonText: 'Oui, supprimer',
    confirmButtonVariant: 'danger',
    cancelButtonText: 'Annuler',
    handleConfirm: onConfirm,
  }));
};

export const handleDocumentClick = (dispatch, accessRights, ins, document) => {
  if (canConsultDocument(document, accessRights)) {
    dispatch(push(`/dmp/${encodeIns(ins)}/document/${encodeIns(getUniqueUUid(document.s_uniqueId, document.s_uuid))}`));
  } else {
    dispatch(setModalInformation('Vous n\'êtes pas autorisé à consulter ce document'));
  }
};

export const getAuthorNbDocs = (rpps, docs, accessRights) => Object.values(docs || {}).reduce((count, doc) => {
  if (
    canConsultDocument(doc, accessRights)
    && doc.Authors
    && doc.Authors.findIndex(a => a.s_hpInternalId === rpps) !== -1
  ) return count + 1;
  return count;
}, 0);

export const getAuthorNbHr = (rpps, parsedHr) => Object.values(parsedHr || {}).reduce((count, hr) => {
  if (
    hr.values
    && hr.values.findIndex(v => (v.author && v.author.rpps === rpps) || (v.performer && v.performer.rpps === rpps)) !== -1
  ) return count + 1;
  return count;
}, 0);


export const sendToEmail = (dispatch, content, document, ins, healthcareSetting) => {
  dispatch(resetMssEmailContent(true));
  dispatch(addMssEmailAttachment({
    patientIns: ins,
    fileContentInBase64: content,
    documentTitle: document.s_title,
    documentDescription: document.s_description,
    documentCategory: document.s_typeCode,
    documentFormat: document.i_document_Format,
    healthcareSetting,
  }));
  dispatch(setShowMssPopup(true));
};

export const getDocumentPreviousVersions = (documents, uuid, depth = 0) => {
  const updates = [];

  if (depth < 10) {
    const previousDoc = Object.values(documents).find(doc => doc.s_nextUUId === uuid);

    if (previousDoc) {
      if (
        previousDoc.s_uuid !== uuid
        && previousDoc.s_uuid !== previousDoc.s_previousUUId
        && previousDoc.s_uuid !== previousDoc.s_nextUUId
      ) {
        updates.push(previousDoc);
        const previousUpdates = getDocumentPreviousVersions(documents, previousDoc.s_uuid, depth + 1);
        if (previousUpdates.length > 0) {
          updates.push(...previousUpdates);
        }
      }
    }
  }

  return sortDocuments(updates.filter(doc => doc.s_uuid !== uuid));
};

export const getDocumentNextVersions = (documents, uuid, depth = 0) => {
  const updates = [];
  if (depth < 10) {
    const nextDoc = Object.values(documents).find(doc => doc.s_previousUUId === uuid);

    if (nextDoc) {
      if (
        nextDoc.s_uuid !== uuid
        && nextDoc.s_uuid !== nextDoc.s_previousUUId
        && nextDoc.s_uuid !== nextDoc.s_nextUUId
      ) {
        updates.push(nextDoc);
        const nextUpdates = getDocumentNextVersions(documents, nextDoc.s_uuid, depth + 1);
        if (nextUpdates.length > 0) {
          updates.push(...nextUpdates);
        }
      }
    }
  }
  return sortDocuments(updates.filter(doc => doc.s_uuid !== uuid));
};

export const getDocumentVersions = (documents, uuid) => {
  const previouses = getDocumentPreviousVersions(documents, uuid);
  const nexts = getDocumentNextVersions(documents, uuid);
  const current = Object.values(documents).find(doc => doc.s_uuid === uuid);

  const versions = [...previouses, ...nexts, current];
  return sortDocuments(versions);
};

export const getDocumentCdaHeaders = (state, uniqueUUid) => {
  const {
    dmpconnect: {
      [apiSections.DOCUMENT_CONTENT_SECTION]: {
        [uniqueUUid]: document = {},
      },
    },
  } = state;

  return getCdaHeaders(document);
};

export const getDocumentCdaContent = (state, uniqueUUid) => {
  const {
    dmpconnect: {
      [apiSections.DOCUMENT_CONTENT_SECTION]: {
        [uniqueUUid]: document = {},
      },
    },
  } = state;

  return getCdaContent(document);
};

export const getDocumentContentFromUniqueId = (state, uniqueUUid) => {
  const {
    dmpconnect: {
      [apiSections.DOCUMENT_CONTENT_SECTION]: {
        [uniqueUUid]: document = {},
      },
    },
  } = state;

  return getDocumentData(document);
};
