import { userAuthenticationContexts, userAuthenticationModes } from '../constants';

export function getEsUserAuthenticationMode(strongAuthMode) {
  switch (Number(strongAuthMode)) {
    case 1:
      return userAuthenticationModes.SmartcardPKI.value;
    case 2:
      return userAuthenticationModes.SmartcardPKI.value;
    case 3:
      return userAuthenticationModes.PreviousSession.value;
    case 4:
      return userAuthenticationModes.MobileTwoFactorContract.value;
    case 5:
      return userAuthenticationModes.MobileTwoFactorContract.value;
    case 6:
      return userAuthenticationModes.InternetProtocolPassword.value;
    case 100:
      return userAuthenticationModes.AuthContext_Unspecified.value;
    default:
      return userAuthenticationModes.Password.value;
  }
}

export function getEsUserAuthenticationContext(strongAuthMode) {
  switch (Number(strongAuthMode)) {
    case 1:
      return userAuthenticationContexts.AuthContext_CPS.value;
    case 2:
      return userAuthenticationContexts.AuthContext_CARTE_ETS.value;
    case 3:
      return userAuthenticationContexts.AuthContext_CPSSANSCONTACT.value;
    case 4:
      return userAuthenticationContexts.AuthContext_OTP_SMS.value;
    case 5:
      return userAuthenticationContexts.AuthContext_APP_MOBILE_AUTH.value;
    case 6:
      return userAuthenticationContexts.AuthContext_APP_BROWSER_AUTH.value;
    case 100:
    default:
      return userAuthenticationContexts.AuthContext_Unspecified.value;
  }
}
