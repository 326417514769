export const daysSinceLastConfigChange = (lastChangeDate) => {
  if (lastChangeDate === null) return 0;

  const today = new Date();
  const lastChangeDatetime = new Date(lastChangeDate);

  return Math.floor((today - lastChangeDatetime) / (1000 * 60 * 60 * 24));
};

export const daysToConfigChange = (lastChangeDate, changeInterval) => {
  const diff = daysSinceLastConfigChange(lastChangeDate);
  const days = changeInterval - diff;
  return days > 0 ? days : 0;
};


export const isTimeToChangeConfig = (lastChangeDate, changeInterval) => {
  const diff = daysSinceLastConfigChange(lastChangeDate);
  return diff > changeInterval;
};
