import React from 'react';
import PropTypes from 'prop-types';
import TitleTooltip from '../../TitleTooltip';

function CustomCheckbox({
  checked, label, onChange, inline, id, indeterminate, showLabel, className, type, ...props
}) {
  const setCheckboxRef = (checkbox) => {
    if (checkbox) {
      // eslint-disable-next-line no-param-reassign
      checkbox.indeterminate = indeterminate;
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = checked;
    }
  };
  const checkboxClassname = inline ? 'custom-control-inline' : 'form-check';
  return (
    <div className={`custom-control custom-${type} ${className} ${checkboxClassname}`}>
      <input
        id={id}
        type={type}
        className="custom-control-input"
        onChange={onChange}
        ref={setCheckboxRef}
        {...props}
      />
      {showLabel ? (
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      ) : (
        <TitleTooltip
          placement="bottom"
          id={id}
          text={label}
        >
          <label className="custom-control-label" htmlFor={id} />
        </TitleTooltip>
      )}
    </div>
  );
}

CustomCheckbox.defaultProps = {
  onChange: () => {
  },
  inline: false,
  indeterminate: false,
  label: null,
  showLabel: true,
  className: '',
  type: 'checkbox',
};

CustomCheckbox.propTypes = {
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node,
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default CustomCheckbox;
