import React from 'react';
import PropTypes from 'prop-types';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { connect } from 'react-redux';
import { getApiType } from 'dmpconnectjsapp-base/helpers/accessors';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import About from '../Header/About';
import env from '../../../envVariables';
import { formatVersion } from '../../../dmpconnect/utils/version';
import { API_TYPES } from '../../../dmpconnect/constants';
import { getLogLevels } from '../../../dmpconnect/actions';
import TitleTooltip from '../TitleTooltip';
import { areLogsDetailed } from '../../../dmpconnect/helpers/logs';

function Footer({
  apiType, connectorVersion, dispatch, logLevelSection, loggedIn,
}) {
  React.useEffect(() => {
    if (apiType !== API_TYPES.REST && !isReady(logLevelSection) && !isLoading(logLevelSection) && !hasError(logLevelSection)) {
      dispatch(getLogLevels());
    }
  }, [isReady(logLevelSection), isLoading(logLevelSection), hasError(logLevelSection)]);

  return (
    <div id="footer">
      <div className="text-center">
        <span>
          Efficience version
          {' '}
          {formatVersion(env.REACT_APP_VERSION)}
        </span>
        {' - '}
        <span>
          {apiType === API_TYPES.REST && 'DmpConnect-ES REST'}
          {apiType === API_TYPES.WS && 'DmpConnectJS'}
          {apiType === API_TYPES.JSON && 'DmpConnect-JSON'}
          {apiType !== API_TYPES.REST && connectorVersion ? ` version ${connectorVersion}` : ''}
        </span>
        {' - '}
        <span>
          Copyright 2000-2180, icanopée. Tous droits réservés
        </span>
        {' - '}
        <a
          href="https://www.icanopee.fr/politique-de-confidentialite/"
          target="_blank"
          rel="noopener noreferrer"
          className="typography-small-link"
        >
          Mentions légales & politique de confidentialité
        </a>
        {' - '}
        <About />

        {areLogsDetailed(logLevelSection) && (
          <>
            {' - '}
            <TitleTooltip
              id="logs-level-tooltip"
              text={(
                <>
                  Les journaux détaillés sont activés.
                  <br />
                  Ils permettent de mieux diagnostiquer les problèmes mais peuvent ralentir l&apos;application.
                </>
              )}
            >

              <Button
                className="btn-unstyled typography-small-link btn btn-link"
                variant="link"
                as={loggedIn ? Link : undefined}
                to={loggedIn ? '/configuration/support' : undefined}
              >
                Diagnostics activés
              </Button>
            </TitleTooltip>
          </>
        )
        }
      </div>
    </div>
  );
}

Footer.defaultProps = {
  connectorVersion: null,
  logLevelSection: null,
};

Footer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  apiType: PropTypes.string.isRequired,
  connectorVersion: PropTypes.string,
  logLevelSection: PropTypes.object,
  loggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.SESSION_SECTION]: {
        s_serviceVersion,
      },
      [apiSections.GET_LOG_LEVELS]: logLevelSection,
    },
    dmpconnectUser: {
      loggedIn,
    },
  } = state;

  return {
    apiType: getApiType(state),
    loggedIn,
    connectorVersion: s_serviceVersion,
    logLevelSection,
  };
}

export default connect(mapStateToProps)(Footer);
