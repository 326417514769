import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';

export const getParcoursSoinUrl = ins => `/dmp/${encodeIns(ins)}/parcours-soin`;
export const getAldDmpUrl = ins => `/dmp/${encodeIns(ins)}/ald`;
export const getAldVitaleUrl = subSection => `/ald/${encodeIns(subSection)}`;
export const getDocumentsUrl = ins => `/dmp/${encodeIns(ins)}/documents`;
export const getAdminUpdateUrl = ins => `/dmp/${encodeIns(ins)}/administration/update`;

export const backPagesTypes = {
  DASHBOARD: 'dashboard',
  DMP: 'dmp',
  PREVIOUS: 'previous',
  MSS: 'mss',
};

export const getBackPage = (type, state) => {
  const {
    dmpconnectApplication: {
      backPages: {
        [type]: backPage,
      },
    },
  } = state;

  return backPage;
};

export const getHomePagePath = (state) => {
  const {
    dmpConnectPersistedAppConfiguration: {
      // efficienceDP,
      mssClientMode,
    },
  } = state;

  // if (efficienceDP) return '/dp';
  if (mssClientMode) return '/mss';
  return '/home';
};
