import {
  all, call, put, spawn, select, take,
} from 'redux-saga/effects';

import { REHYDRATE } from 'redux-persist/lib/constants';
import {
  handleAsynchronousCommand,
  handleHTTPCommand, handleHTTPSynchronousCommand, handleJSONCommand, handleJSONSynchronousCommand,
  handleSynchronousCommand,
} from 'dmpconnectjsapp-base/sagas/connectorSagas';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import { handleDMPSequence, handleJSONSequence } from 'dmpconnectjsapp-base/sagas/initializationSagas';
import { handleRESTSequence } from 'dmpconnectjsapp-base/sagas/rest/securitySagas';
import { handleJsonMonitoring, handleMonitoring } from 'dmpconnectjsapp-base/sagas/monitoringSagas';
import { createError, errorTypes, softwareErrors } from 'dmpconnectjsapp-base/errors';
import { setModalError } from 'dmpconnectjsapp-base/actions';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { handleCallbacks } from '.';
import { handleDmpconnectErrors } from './connectorSagas';
import { handleLocationChange } from './locationSaga';
import { handleCPXMonitoringUpdate } from './monitoringSagas';
import { handleReceivedRemoteRequest, handleRemoteMonitoring } from './remoteSagas';
import { handleManageDMPProcess } from './administrationSaga';
import {
  handleVirtualPrinterCommand,
  handleVirtualPrinterFile,
  handleVirtualPrinterMonitoring, handleVirtualPrinterSendDocumentProcess,
} from './virtualPrinterSagas';
import { handleRemoteCommand } from './connectorRemoteSagas';
import { createErrorDetails, createModalError } from '../errors';
import { storages } from '../../reducers';
import { handlePatientCallbacks } from './patientSagas';
import { handleDpCallbacks } from './dpSagas';
import { saveUserDataInPersistantDataQueue } from './userSagas';
import { handleLogsCallbacks } from './logsSaga';

const getEfficienceDP = ({ dmpConnectPersistedAppConfiguration }) => getConfigurationValue(
  'efficienceDP',
  dmpConnectPersistedAppConfiguration,
);

const mapStackTracePromise = e => new Promise((resolve, reject) => {
  mapStackTrace(e.stack, mappedStack => resolve(mappedStack));
});

export default function* rootSaga() {
  if (storages && storages.length > 0) {
    let rehydrated = false;
    const rehydratedStorages = [];
    while (rehydrated === false) {
      const { key } = yield take(REHYDRATE); // Wait for rehydrate to prevent sagas from running with empty store
      rehydratedStorages.push(key);
      if (storages.every(s => rehydratedStorages.includes(s))) {
        rehydrated = true;
      }
    }
  }
  const efficienceDP = yield select(getEfficienceDP);

  const sagas = [
    { saga: handleCallbacks },
    { saga: saveUserDataInPersistantDataQueue },
    { saga: handlePatientCallbacks },
    { saga: handleSynchronousCommand },
    { saga: handleAsynchronousCommand },
    { saga: handleHTTPCommand },
    { saga: handleHTTPSynchronousCommand },
    { saga: handleJSONCommand },
    { saga: handleJSONSynchronousCommand },
    { saga: handleDmpconnectErrors },
    { saga: handleJSONSequence }, // séquence d'initialisation JSON
    { saga: handleDMPSequence }, // séquence d'initialisation JS
    { saga: handleRESTSequence }, // séquence d'initialisation REST
    { saga: handleLocationChange },
    { saga: handleJsonMonitoring, arg: 'cpx' },
    { saga: handleMonitoring, arg: 'cpx' },
    { saga: handleMonitoring, arg: 'vitale' },
    { saga: handleCPXMonitoringUpdate },
    { saga: handleReceivedRemoteRequest },
    { saga: handleManageDMPProcess },
    { saga: handleVirtualPrinterMonitoring },
    { saga: handleVirtualPrinterFile },
    { saga: handleVirtualPrinterCommand },
    { saga: handleVirtualPrinterSendDocumentProcess },
    { saga: handleRemoteMonitoring },
    { saga: handleRemoteCommand },
    { saga: handleLogsCallbacks },
  ];

  if (efficienceDP) {
    sagas.push({ saga: handleDpCallbacks });
  }


  yield all(sagas.map(({ saga, arg }) => spawn(function* () {
    while (true) {
      try {
        yield call(saga, arg);
        break;
      } catch (e) {
        const mappedStack = yield call(mapStackTracePromise, e);

        // console.error('unexpected error', e);
        const errorModal = createError(
          errorTypes.SoftwareErrors,
          softwareErrors.UNEXPECTED_ERROR,
        );

        const details = [createErrorDetails('Error', {
          name: e.name,
          message: e.message,
          toString: e.toString(),
          stack: mappedStack.join('\n'),
        })];
        yield put(setModalError(createModalError(errorModal, details)));
      }
    }
  })));
}
