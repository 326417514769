export const routes = {
  logout: { path: '/logout' },
  home: { path: '/' },
  dmpSearch: { path: '/home/:page?/:extra?' },
  dp: { path: '/dp/:encodedNdp' },
  configuration: { path: '/configuration/:tab?' },
  debug: { path: '/debug' },
  esConfig: { path: '/es-config' },
  tseConfig: { path: '/tse-config' },
  cpxConfig: { path: '/cpx-config' },
  meds: { path: '/dmp/:encodedIns/meds' },
  vidalConfig: { path: '/vidal-config' },
  dmpDocument: { path: '/dmp/:encodedIns/document/:encodedUniqueUUid' },
  dmpDocuments: { path: '/dmp/:encodedIns/documents' },
  dmpDocumentsCategory: { path: '/dmp/:encodedIns/documents/:typeCode' },
  dmpInformation: { path: '/dmp/:encodedIns/information' },
  dmpAdministration: { path: '/dmp/:encodedIns/administration/:tab?' },
  webAccessPDFViewer: { path: '/dmp/view_web_access/:action' },
  listview: { path: '/dmp/:encodedIns/parcours-soin' },
  aldVitale: { path: '/ald/:subSection' },
  aldDmp: { path: '/dmp/:encodedIns/ald' },
  mss: { path: '/mss/:mssFolderId?/:mssMessageId?' },
  sentDocuments: { path: '/sentdocuments' },
  logs: { path: '/logs' },
};
