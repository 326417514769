import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  getLoginReferer,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import {
  findDocumentByUniqueId,
  getFindDocumentsLastSearchTimeStamp as getFindDocumentsLastSearchTimeStampAccessor,
  getFindDocumentsParams as getFindDocumentsParamsAccessor,
} from '../helpers/findDocuments';
import { clearLoginReferer } from '../actions';
import { getHomePagePath } from '../helpers/nav';


export const redirectAfterLogin = function* () {
  const loginReferer = yield select(getLoginReferer);

  if (loginReferer) {
    yield put(clearLoginReferer());
    yield put(push(loginReferer));
  } else {
    const homePagePath = yield select(getHomePagePath);
    yield put(push(homePagePath));
  }
};// const getAllNewDocsFromCache = (state, ins) => {
export const getFindDocumentsParams = state => getFindDocumentsParamsAccessor(state);
export const getFindDocumentsLastSearchTimeStamp = state => getFindDocumentsLastSearchTimeStampAccessor(
  state,
);
export const getAccessRights = state => getAccessRightsProps(state);
export const getDocumentInfoFromCache = (state, ins, uniqueId) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        documents: {
          [uniqueId]: document,
        } = {},
      } = {},
    },
  } = state;
  return document;
};
export const getAllDocsFromCache = (state, ins) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        documents = {},
      } = {},
    },
  } = state;
  return Object.values(documents);
};
//   const {
//     dmpconnectDocumentsCache: {
//         [ins]: {
//           documents,
//         } = {},
//     },
//   } = state;
//   return findNewDocuments(documents);
// };
export const getNewDocFromCache = (state, ins, uniqueId, exeptUuid) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        documents,
      } = {},
    },
  } = state;

  const [doc] = findDocumentByUniqueId(documents, uniqueId, exeptUuid);
  return doc;
};
