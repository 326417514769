import { Button, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
  mssActionConstants,
  mssLoginTypes,
  mssReceiptNotificationTypes,
} from 'dmpconnectjsapp-base/constants';
import { mssOperators, mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import { setMssConfiguration } from 'dmpconnectjsapp-base/actions';
import { setUserPreferences } from '../../dmpconnect/actions';
import SwitchCheckbox from '../Common/Form/Input/SwitchCheckbox';
import { isMssActive } from '../../dmpconnect/helpers';
import MssConfig from './MssConfig';
import MssConfigOTP from './MssConfigOTP';
import { getMssReplyTo } from '../../dmpconnect/helpers/mss';
import Alert from '../Common/Message/Alert';
import env from '../../envVariables';
import MssOTPLogin from './MssOTPLogin';

function MssConfiguration({
  dispatch,
  columnLayout,
  mssActive,
  config,
  title,
  mssLoginType,
  mssApiType,
  mssReceiptNotificationType,
  mssOperator,
  mssReplyTo,
  login,
  otpLogin,
}) {
  if (otpLogin) {
    return <MssOTPLogin />;
  }
  const [enabled, setEnabled] = React.useState(mssActive);
  const [apiType, setApiType] = React.useState(mssApiType);
  const [loginType, setLoginType] = React.useState(mssLoginType);
  const [receiptNotificationType, setReceiptNotificationType] = React.useState(mssReceiptNotificationType);
  const [replyTo, setReplyTo] = React.useState(mssReplyTo);
  const [operator, setOperator] = React.useState(mssOperator);
  const [advanced, setAdvanced] = React.useState(false);

  const submitMss = () => {
    dispatch(setUserPreferences({ mssActive: enabled }));
    dispatch(setMssConfiguration('mssOperator', operator));
    dispatch(setMssConfiguration('mssApiType', apiType));
    dispatch(setMssConfiguration('mssLoginType', loginType));
    dispatch(setMssConfiguration('mssReceiptNotificationType', receiptNotificationType));

    if (apiType !== mssApiType) {
      dispatch({ type: mssActionConstants.EMPTY_ALL_MSS_MESSAGES });
    }
  };

  const advancedContent = (
    <>
      {config && (
        <>
          <div className="btn-group-toggle mb-3">
            <Button
              size="sm"
              className={`toggle-button ${advanced ? 'active' : ''}`}
              onClick={() => setAdvanced(!advanced)}
            >
              <span>
                {advanced ? 'Cacher ' : 'Afficher ' }
                la configuration avancée
              </span>
            </Button>
          </div>
          {advanced && (
            <div id="advanced-mss-config">
              <h6 className="mt-3">Configuration avancée</h6>
              <Form.Group>
                <Form.Label>
                  Mode d&apos;accès
                </Form.Label>
                <Form.Control
                  as="select"
                  name="mssApiType"
                  onChange={event => setApiType(event.target.value)}
                  value={apiType}
                  disabled={!enabled}
                >
                  <option value={mssSubTypes.IMAP}>IMAP/SMTP</option>
                  {operator === mssOperators.MAILIZ && (
                    <option value={mssSubTypes.WEB}>Web Services (Mailiz)</option>
                  )}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Mode d&apos;authentification
                </Form.Label>
                <Form.Control
                  as="select"
                  name="mssLoginType"
                  onChange={event => setLoginType(event.target.value)}
                  value={loginType}
                  disabled={!enabled}
                >
                  <option key={mssLoginTypes.CPX} value={mssLoginTypes.CPX}>CPx</option>
                  <option key={mssLoginTypes.CERT} value={mssLoginTypes.CERT}>Certificat logiciel</option>
                  {operator === mssOperators.MAILIZ && apiType === mssSubTypes.WEB && (
                    <option key={mssLoginTypes.OTP} value={mssLoginTypes.OTP}>OTP</option>
                  )}
                  {operator === mssOperators.MAILIZ && apiType === mssSubTypes.IMAP && Number(env.REACT_APP_MSS_PSC) === 1 && (
                    <option key={mssLoginTypes.PSC} value={mssLoginTypes.PSC}>{mssLoginTypes.PSC}</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Type d&apos;accus&eacute; de r&eacute;ception
                </Form.Label>
                <Form.Control
                  as="select"
                  name="mssReceiptNotificationType"
                  onChange={event => setReceiptNotificationType(event.target.value)}
                  value={receiptNotificationType}
                  disabled={!enabled}
                >
                  <option value={mssReceiptNotificationTypes.DSN}>
                    Delivery Status Notification (DSN) (expérimental)
                  </option>
                  <option value={mssReceiptNotificationTypes.MDN}>
                    Message Delivery Notification (MDN)
                  </option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={columnLayout ? Row : undefined}>
                <Form.Label column={columnLayout} sm="4">
                  Adresse de réponse
                </Form.Label>
                <div className={columnLayout ? 'col-sm-8' : undefined}>
                  <Form.Control
                    id="mssReplyTo"
                    disabled={config && !mssActive}
                    onChange={event => setReplyTo(event.target.value)}
                    name="mssReplyTo"
                    value={replyTo}
                  />
                </div>
              </Form.Group>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-start w-full">
        <div>
          {title && (
          <h5 className="mb-4">{title}</h5>
          )}

          <Form className="flex-grow-1 mr-5">
            {config && (
              <>
                <h6>Configuration de l&apos;accès MSS</h6>
                {!login && (
                <Form.Group as={columnLayout ? Row : undefined}>
                  <Form.Label column={columnLayout} sm="4">
                    Activer MSS
                  </Form.Label>
                  <div className={`d-flex v-centered ${columnLayout ? 'col-sm-8' : undefined}`}>
                    <SwitchCheckbox
                      id="mssActive"
                      onChange={(event) => {
                        setEnabled(event.target.checked);
                        dispatch(setUserPreferences({ mssActive: event.target.checked }));
                      }}
                      name="mssActive"
                      checked={enabled}
                      showLabel
                      onLabel="Activé"
                      offLabel="Désactivé"
                    />
                  </div>
                </Form.Group>
                )}
                <Form.Group className="mb-0">
                  <Form.Label>
                    Opérateur
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="mssApiType"
                    onChange={event => setOperator(event.target.value)}
                    value={operator}
                    disabled={!enabled}
                  >
                    <option value={mssOperators.MAILIZ}>Mailiz</option>
                    <option value={mssOperators.OTHERS}>Autre opérateur</option>
                  </Form.Control>
                  <div className="mt-3">
                    {operator === mssOperators.MAILIZ && (
                    <Alert type="info" className="mb-0">
                      Mailiz est l&apos;opérateur de messagerie sécurisée de santé (MSS) gratuite de l&apos;ANS (Agence du Numérique en Santé).
                      <br />
                      Tout professionnel de santé peut créer son compte sur :
                      {' '}
                      <a
                        href={Number(env.REACT_APP_PRODUCTON_MODE) === 1
                          ? 'https://mailiz.mssante.fr/'
                          : 'https://mailiz.formation.mssante.fr/'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {Number(env.REACT_APP_PRODUCTON_MODE) === 1
                          ? 'https://mailiz.mssante.fr/'
                          : 'https://mailiz.formation.mssante.fr/'
                        }
                      </a>
                      .
                    </Alert>
                    )}
                    {operator === mssOperators.OTHERS && (
                    <Alert type="info" className="mb-0">
                      Ce choix vous permet d&apos;accéder à tout opérateur de MSS conforme SEGUR.
                      <br />
                      Tous les opérateurs ne sont pas encore conformes.
                      <br />
                      Les réglages de l&apos;accès technique à la messagerie sont manuels.
                      <br />
                      Dans le futur ces réglages seront effectués automtatiquement.
                      <br />
                      Merci de vous rapprocher de votre éditeur ou d&apos;icanopée pour configurer cette section.
                    </Alert>
                    )}
                  </div>
                </Form.Group>

              </>
            )}

            {loginType === mssLoginTypes.OTP ? (
              <MssConfigOTP
                columnLayout={columnLayout}
                config={config}
                mssApiType={apiType}
                mssLoginType={loginType}
                mssActive={enabled}
                mssReceiptNotificationType={receiptNotificationType}
                onSubmit={() => submitMss()}
                mssReplyTo={replyTo}
                mssOperator={operator}
                advancedContent={advancedContent}
              />
            ) : (
              <MssConfig
                columnLayout={columnLayout}
                config={config}
                mssApiType={apiType}
                mssLoginType={loginType}
                mssActive={enabled}
                mssReceiptNotificationType={receiptNotificationType}
                onSubmit={() => submitMss()}
                mssReplyTo={replyTo}
                mssOperator={operator}
                advancedContent={advancedContent}
              />
            )}


          </Form>
        </div>
        <i className="ic-mss" />
      </div>
    </>
  );
}

MssConfiguration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  columnLayout: PropTypes.bool,
  mssActive: PropTypes.bool,
  config: PropTypes.bool,
  login: PropTypes.bool,
  otpLogin: PropTypes.bool,
  title: PropTypes.string,
  mssReplyTo: PropTypes.string,
  mssOperator: PropTypes.string,
  mssLoginType: PropTypes.string.isRequired,
  mssApiType: PropTypes.string.isRequired,
  mssReceiptNotificationType: PropTypes.string.isRequired,
};
MssConfiguration.defaultProps = {
  columnLayout: true,
  mssActive: false,
  config: true,
  login: false,
  otpLogin: false,
  title: '',
  mssReplyTo: '',
  mssOperator: mssOperators.MAILIZ,
};

function mapStateToProps(state, props) {
  const {
    dmpconnectMSSConfiguration: {
      mssLoginType,
      mssApiType,
      mssReceiptNotificationType,
      mssOperator,
    },
  } = state;

  return {
    mssActive: isMssActive(state),
    mssLoginType,
    mssApiType,
    mssReceiptNotificationType,
    mssOperator,
    mssReplyTo: getMssReplyTo(state),
    otpLogin: mssLoginType === mssLoginTypes.OTP && props.login === true,
  };
}

export default connect(mapStateToProps)(MssConfiguration);
